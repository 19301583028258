import { Options, Vue } from "vue-class-component";
import { Row, Col, Form, Input, Image, Radio, message ,Button,Alert} from "ant-design-vue";
import { LoginOutlined, UserOutlined } from "@ant-design/icons-vue";
import { Captcha } from "@/api/Captcha";
import { Register as apiLogin } from "@/api/Register";
import { SendEmailCode as sendCode } from "@/api/SendEmailCode";
import zhTW from "ant-design-vue/es/locale/zh_TW";
import enUS from "ant-design-vue/es/locale/en_US";
@Options({
  name: "Login",
  components: {
    Row,
    Col,
    LoginOutlined,
    UserOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputSearch:Input.Search,
    InputPassword: Input.Password,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Button,
    Alert
  },
  data() {
    return {
      data: {
        email: "",
        password: "",
        repassword:'',
        nickname:'',
        type:0,
        companyName:'',
        companyAddress:'',
        code:null,
      },
      value1:'',
      error:null,
      isLoad:false,
      captchaImg: "",
      captchaExpire: 0,
      language: "zhTW",
      validateRules: {
        required: '${label} is required',
        types: {
          email: 'is not a valid email',
        }
      },
    };
  },
  methods: {
    // get captcha
    getCaptcha() {
      const api = new Captcha();
      api.get({ type: 1 }).then((response: any) => {
        if (1 == parseInt(response.data.code)) {
          //this.captchaImg = response.data.data.image;
          this.login.captcha_key = response.data.data.key;
          this.captchaExpire = parseInt(response.data.data.expire_time);
        } else {
          this.captchaImg = "";
          this.login.captcha_key = "";
          this.captchaExpire = 0;
        }
      });
    },
    // change language
    changeLanguage() {
      switch (this.language) {
        case "zhTW":
          this.locale = zhTW;
          this.$i18n.locale = "zhTW";
          this.$store.commit("setLanguage", "zhTW");
          break;
        case "enUS":
          this.locale = enUS;
          this.$i18n.locale = "enUS";
          this.$store.commit("setLanguage", "enUS");
          break;
      }
    },
    // send login message
    sendLogin() {
      this.data.code = this.code;
      this.$refs["refLogin"]
        .validate()
        .then(() => {
          const api = new apiLogin();
          this.isLoad  =true;
          this.error = null;
          api.post(this.data).then((response: any) => {
            this.isLoad = false;
            if (0 == parseInt(response.data.errno)) {
              //this.$store.commit("setToken", response.data.data.corsToken);
              //this.$store.commit("setUser", response.data.data);
              message.success(response.data.errmsg);
              //this.$router.push({ name: "/login" });
              location.href = "#/login"
            } else {
              this.error = response.data.errmsg;
              //message.error(response.data.errmsg);
              // this.getCaptcha();
            }
          });
        })
        .catch();
    },
    onSendCode(){
      if(this.data.email){
        const api = new sendCode();
        api.post({
          email: this.data.email
        }).then((response: any) => {
          if (0 == parseInt(response.data.errno)) {
            message.success(response.data.errmsg);
          } else {
            this.error = response.data.errmsg;
          }
        });
      }else{
        message.error("請輸入Email!")
      }
    }

  },
  mounted() {
    //this.checkLogin();
    var query = this.$route.query;
    if(query.agent != undefined && query.agent != ''){
       this.$store.commit("setAgentCode",query.agent);
       this.code = query.agent;
    }else{
      this.code = this.$store.getters.getAgentCode;
    }
    this.language = this.$store.getters.getLanguage;
  },
})
export default class Login extends Vue { }
/**
 * @Auther    william
 * @Name      state mutations file
 * @Note      
 * @Time      2021/04/14 17:21
 */

import { MutationTree } from "vuex"
import store from '@/store'
import heart from '../libs/heart'
import websocket from '../libs/websocket'
import { GetFreeNode } from '@/api/GetFreeNode'
let mutations: MutationTree<any> = {
    // test
    test(state: any): void {
        console.log(state)
    },
    // logout
    logout(state: any): void {
        localStorage.removeItem(state.systemTag + "_info")
        this.commit("reset")
    },
    // reset state
    reset(state: any): void {
        let tmp = localStorage.getItem(state.systemTag + "_info")
        if (tmp) {
            let info = JSON.parse(tmp)
            state.language = info.language
            state.token = info.token
            state.logo = info.logo
            state.copyRight = info.copyRight
            state.navList = info.navList
            state.username = info.username
            state.portrait = info.portrait
            state.lastIp = info.lastIp
            state.lastTime = info.lastTime,
            state.user = info.user
            state.menus = info.menus
            state.agentCode = info.agentCode
        } else {
            state.language = "zhTW"
            state.token = ""
            state.logo = ""
            state.copyRight = "Copyright © 2021."
            state.navList = []
            state.username = ""
            state.portrait = ""
            state.lastIp = ""
            state.lastTime = 0
            state.user = []
            state.menus = {}
            state.agentCode = ""
        }
    },
    // set state
    setInfo(state: any): void {
        let info = {
            language: state.language,
            token: state.token,
            logo: state.logo,
            copyRight: state.copyRight,
            navList: state.navList,
            username: state.username,
            portrait: state.portrait,
            lastIp: state.lastIp,
            lastTime: state.lastTime,
            user:state.user,
            menus:state.menus,
            agentCode:state.agentCode
        }
        localStorage.setItem(state.systemTag + "_info", JSON.stringify(info))
    },
    // set language
    setLanguage(state: any, value: string): void {
        state.language = value
        this.commit("setInfo")
    },
    // set username
    setUsername(state: any, value: string): void {
        state.username = value
        this.commit("setInfo")
    },
    // set portrait
    setPortrait(state: any, value: string) {
        state.portrait = value
        this.commit("setInfo")
    },
    // set last login ip
    setLastIp(state: any, value: string) {
        state.lastIp = value
        this.commit("setInfo")
    },
    // set last login time
    setLastTime(state: any, value: string) {
        state.lastTime = value
        this.commit("setInfo")
    },
    // set toekn
    setToken(state: any, value:string) {
        state.token = value
        this.commit("setInfo")
    },
    // 設置推廣代碼
    setAgentCode(state: any, value:string){
        state.agentCode = value
        this.commit("setInfo")
    },
    // set toekn
    setUser(state: any, value:string) {
        debugger
        state.user = value
        this.commit("setInfo")
    },
    // set toekn
    setMenus(state: any, value:any) {
        state.menus = value
        this.commit("setInfo")
    },
    setLoading(state: any, value:any){
        state.loadding = value  
    },
    /**
     * WebSocket鏈接處理
     * @param state 
     * @param value 
     */
    async connectSocket(state: any, value:any){
        if(JSON.stringify(state.socketIo) === '{}'){
            let that = this;
            // let ws = process.env.NODE_ENV === 'production' ? state.wsDomain : state.wsDomain;
            let ws = ''
            let getNodeApi = new GetFreeNode();
            await getNodeApi.get().then( (res : any) => {
                console.log(res);
                ws = res.data.data
            })
            ws = ws.replace('https://','wss://');
            ws = ws.replace('http://','ws://');
            console.log("ws:" + ws)
            state.socketIo = new WebSocket(ws+'/ws?EIO=4&transport=websocket',[state.token]); //ws://ws.ectg.net:8362/ws
            
            state.socketIo.onopen = function(data:any){
                //state.message = {event:'opend',data:'This client'};
                console.log('socket connect...');
                //that.commit("startHeart")
            }
            // 鏈接關閉，重新鏈接
            state.socketIo.onclose = function(event:any){
                console.log('socket is closed now.',event);
                state.socketIo = {};
                that.commit("connectSocket")
            }
            // 鏈接出錯
            state.socketIo.onerror = function () {
                console.log('socket is error.');
                state.socketIo = {};
                that.commit("connectSocket")
            }
            state.socketIo.onmessage = function(event:any){
                that.commit("startHeart")
                let result = JSON.parse(event.data);
                if(result.event == 'heart'){
                    return false;
                }
                state.message = result;
            }
            window.onbeforeunload = function() {
                state.socketIo.close();
            }
        }
    },
    /**
     * 檢測WebSocket鏈接是否成功，發送消息到後台
     * @param state 
     * @param value 
     */
    socketSend(state:any, value:any): any {
        let isConnected = false;
        let i=0;
        do
        {
            if(state.socketIo.readyState == 1){
                isConnected = true;
                state.socketIo.send(value);
                i=0;
            }
            i++;
        }while (!isConnected && i < 3);
    },
    startHeart(state: any, value:any){
        heart.reset().start(state.socketIo);
    }
}


export default mutations
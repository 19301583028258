import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/vue/Home.vue'
import Login from "@/views/vue/Login.vue"
import Register from "@/views/vue/Register.vue"
import Recover from "@/views/vue/Recover.vue"
import store from "@/store"
const routes: Array<RouteRecordRaw> = [
  {
    path: "/login/:authToken*",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/recover",
    name: "Recover",
    component: Recover
  },
  {
    path: '/',
    name: '',
    component: Home,
    redirect:"/dashboard/index",
    beforeEnter: (to, from, next) => {
      // token存在 进入该路由，不存在跳转到登陆页面
      if (store.getters.getToken) {
        //console.log(store.getters.getToken)
        store.dispatch("socket");
        next()
      } else {
        next('/login')
      }
    },
    children: [
      {
        path: "/dashboard/index",
        name: "dashboard/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/Dashboard.vue')
      },
      {
        path: "rule/list",
        name: "rule/list",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/rule/list.vue') 
      },
      {
        path: "group/index",
        name: "group/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/group/index.vue')
      },
      {
        path: "auth/user",
        name: "auth/user",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/auth/user.vue')
      },
      {
        path: "book/index",
        name: "book/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/book/index.vue')
      },
      {
        path: "general/config",
        name: "general/config",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/general/config.vue')
      },
      {
        path: "general/profile",
        name: "general/profile",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/general/profile.vue')
      },
      {
        path: "attachment/index",
        name: "attachment/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/general/attachment.vue')
      },
      // 發送消息
      {
        path: "chat/message",
        name: "chat/message",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/chat/message.vue')
      },
      { // 消息記錄
        path: "chat/index",
        name: "chat/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/chat/index.vue')
      },
      
      // 设备管理
      {
        path: "device/index",
        name: "device/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/device/index.vue')
      },
      // 客服頁面
      {
        path: "cs/index",
        name: "cs/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/customerService/cs.vue')
      },
      // 设备管理
      {
        path: "device/view",
        name: "device/view",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/device/view.vue')
      },
      // 添加设备
      {
        path: "device/add",
        name: "device/add",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/device/add.vue')
      },
      // 添加客户设备
      {
        path: "device/cusadd",
        name: "device/cusadd",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/device/cusadd.vue')
      },
      // 设备管理
      {
        path: "general/log",
        name: "general/log",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/general/log.vue')
      },
            // NLP設定
            {
              path: "general/nlp",
              name: "general/nlp",
              component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/general/nlp.vue')
            },
      // 支付方式
      {
        path: "addon/payment",
        name: "addon/payment",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/addon/payment.vue')
      },
      // 快速回答
      {
        path: "addon/answer",
        name: "addon/answer",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/fastAnswer/index.vue')
      },
      // 产品套餐
      {
        path: "product/index",
        name: "product/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/product/index.vue')
      },
      // 产品套餐
      {
        path: "item/index",
        name: "item/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/product/item.vue')
      },
      // 订单管理
      {
        path: "order/index",
        name: "order/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/order/index.vue')
      },      
      // 国家
      {
        path: "country/index",
        name: "country/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/country/index.vue')
      },
      // 聯絡人分組
      {
        path: "contactGroup/index",
        name: "contactGroup/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/contactGroup/index.vue')
      },
      // 国家
      {
        path: "user_account/index",
        name: "user_account/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/user_account/index.vue')
      },
      // apiKey
      {
        path: "api_key/index",
        name: "api_key/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/api_key/index.vue')
      },
      {
        path: "meal/index",
        name: "meal/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/meal/index.vue')
      },
      {
        path: "ai_nlp/index",
        name: "ai_nlp/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/fakeNlp/index.vue')
      },
      {
        path: "ai_nlp_session/index",
        name: "ai_nlp_session/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/fakeNlpSession/index.vue')
      },
      {
        path: "ai_nlp_message/index",
        name: "ai_nlp_message/index",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/fakeNlpMessage/index.vue')
      },
      {
        path: "order/easyorder",
        name: "order/easyorder",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/vue/Home/order/easyorder.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

import { Base,Axios } from "@/api/Base"

export class Upload extends Base {
  path = "upload"
  show(url: string): any {
    return Axios.get(url, { responseType: 'arraybuffer' })
  }
  download(url: string): any {
    return Axios.get(url, { responseType: "blob" })
  }
}
import {Axios} from "@/api/Base"
import store from "@/store"

export class GetFreeNode {
    path = process.env.NODE_ENV === 'production' ? "https://whatsapp01.router.ectg.net/api/nodeStatus/getFreeNode" : "http://127.0.0.1:9359/api/nodeStatus/getFreeNode"

    get(): any {
        return Axios.get(this.path,{
                headers: {
                'Access-Control-Allow-Origin':"*",
                "Content-Type": "application/json; charset=UTF-8",
                "ocrs-token": store.getters.getToken,
            }
        })
    }
}
/**
 * @Auther    william
 * @Name      state get function
 * @Note      
 * @Time      2021/04/13 16:07
 */

import { GetterTree } from "vuex"
import { State } from "@/store/state"

let getters: GetterTree<State, any> = {
    // get all state message
    getAll(state): any {
        return state
    },
    // get axios token
    getToken(state): string {
        return state.token
    },
    getUserPortrait(state):string{
        return "";
    },
    // get logo
    getLogo(state): string {
        return state.logo
    },
    // get language
    getLanguage(state): string {
        return state.language
    },
    // get copy right message
    getCopyRight(state): string {
        return state.copyRight
    },
    // get noImage
    getNoImage(state): string {
        return state.noImage
    },
    // get some user message
    getUserMsg(state): object {
        return {
            username: state.username,
            portrait: state.portrait,
            lastIp: state.lastIp,
            lastTime: state.lastTime,
        }
    },
    // get theme msg
    getAgentCode(state): string {
        return state.agentCode
    },
    // get theme msg
    getTheme(state): string {
        return state.theme
    },
    // get theme msg
    getUser(state): object {
        return state.user
    },
    // get theme msg
    getMenus(state): object {
        return state.menus
    }, 
    // 获取请求路径域名
    getDomain(state): string {
        if (process.env.NODE_ENV === 'production') {
            return state.proDomain
        } else {
            return state.devDomain
        }
    },
    // 获取socket io对象
    socketIo(state): object {
        return state.socketIo;
    }
    
}

export default getters
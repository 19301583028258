import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_Col = _resolveComponent("Col")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "PersonalPassword",
    visible: _ctx.visible,
    onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onOk: _ctx.send,
    title: _ctx.$t('member.name.reset_password'),
    zIndex: 7
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        ref: "member_form",
        model: _ctx.data,
        layout: "vertical",
        rules: _ctx.validateRules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Row, { gutter: [16,16] }, {
            default: _withCtx(() => [
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('member.field.password'),
                    name: "password"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InputPassword, {
                        placeholder: _ctx.$t('member.field.password'),
                        value: _ctx.data.password,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.password) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('member.field.repassword'),
                    name: "repassword"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InputPassword, {
                        placeholder: _ctx.$t('member.field.repassword'),
                        value: _ctx.data.repassword,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.repassword) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "title"]))
}
const zhTW = {
    public: {
        name: {
            status: "狀態",
            login: "登錄",
            logout: "登出",
            hello: "你好",
            username: "用戶名",
            email: "E-mail",
            password: "密碼",
            confirm_password: "確認密碼",
            reset_password: "重置密碼",
            captcha: "驗證碼",
            more: "更多",
            action: "操作",
            add: "添加",
            edit: "編輯",
            del: "刪除",
            yes: "是",
            no: "否",
            empty: "空",
            are_you_sure_delete: "你確定需要刪除該信息嗎",
            action_cannot_resumed: "該操作將無法恢復",
            search: "搜索",
            reset: "重置",
            spread: "展開",
            retract: "收回",
            show: "展示",
            no_page: "無頁面",
            service: "服務商",
            phone_country_code: "電話國家代碼",
            name: "名稱",
            phone: "電話",
            sex: "性別",
            country: "國家",
            address: "地址",
            birthday: "出生年月日",
            remark: "備注",
            personal: "個人信息",
            enclosure: "附件",
            logo: "系統圖標",
            download:"下載",
            open:"打開",
            detail:"詳情",
            normal:"正常",
            disabled:"禁用",
        },
        
        language: {
            zhTW: "中文(繁體)",
            enUS: "英文",
            zhCN: "中文(簡體)"
        },
        status: {
            0: "未知",
            1: "正常",
            2: "禁用",
            3: "已删除",
        },
        sex: {
            1: "男",
            2: "女",
            3: "未知"
        },
        placeholder: {
            please_select: "請選擇",
        },
        table: {
            name: "名稱",
            status: "狀態",
            action: "操作",
            content: "內容",
            created_at: "創建時間",
            image: "圖像",
            email: "E-mail",
            order_id: "排序",
            sex: "性別",
            last_ip: "最後登錄IP",
            phone: "電話",
        },
        field: {
            name: "名稱",
            username: "用戶名",
            content: "內容",
            phone_country_code: "電話國家代碼",
            phone: "電話",
            email: "E-mail",
            order_id: "排序",
            portrait: "頭像",
            status: "狀態",
            image: "圖像",
            sex: "性別",
        },
        validate: {
            required: {
                id: "缺少ID",
                username: "請輸入用戶名",
                password: "請輸入密碼",
                repassword: "請重復密碼",
                name: "請輸入名稱",
                phone: "請輸入電話",
                email: "請輸入E-mail",
                portrait: "請上傳頭像",
                phone_country_code: "請選擇電話國家代碼",
            },
            tips: {
                inconsistent_password: "密碼不一致",
                length_should: "長度應爲{min}至{max}"
            }
        }
    },
    language: {
        zhTW: "中文（繁體）",
        enUS: "英語",
        zhCN: "中文(簡體)"
    },
    nav: {
        name: {
            Dashboard: "儀錶盤",
            Auth: "權限",
            Rule: "規則列表",
            Group: "角色列表",
            Nav: "菜單列表",
            NavMenu: "菜單列表",
            Member: "成員列表",
            UploadManage: "上傳管理",
            PhoneCountry: "電話國家代碼",
            Carousel: "輪播圖",
            UserManage: "用戶管理",
            AreaManage: "區域管理",
            AreaList: "區域列表",
            DriverManage: "司機管理",
            DriverList: "司機列表",
            DriverService: "服務商列表",
            DriverOrder: "司機訂單",
            CarRentalManage: "租車管理",
            CarRentalType: "租車類型",
            CarRentalCar: "租車車輛類型",
            CarRentalOrder: "租車訂單",
            SaleCarManage: "銷售車輛管理",
            SaleCar: "銷售車輛",
            SaleCarAppointment: "銷售預約",
            OilCardManage: "油卡管理",
            OilCardType: "油卡類型",
            OilCardOrder: "油卡申請列表",
            FAQManage: "FAQ管理",
            ProtocolManage: "協議管理",
            General:"基礎信息",
            Setting:"基礎設置",
            Attachment:"附件管理",
            UserInfo: "個人中心",
            OperatorLog: "操作日誌",
            OrderList: "訂單列表",
            Authority: "權限管理",
            RoleManage: "角色管理",
            MenuSetting: "菜單設置",
            AccountManage: "賬號管理",
            PackageManage: "套餐升級",
            ProductManage: "產品管理",
            WhatsApp: "Whats App",
            History: "歷史記錄",
            Devices: "設備列表",
            NewMessage: "新信息",
            Books:"聯絡人",
            Plugin: "插件管理",
            PaymentMethod: "支付方式",
            customerService:"客服界面",
            FastAnswer:"直答",
            Nlp:"自動對答管理",
            ContactGroup:"聯絡人分組",
            ApiKey: "應用管理",
            country:"國家",
            devicePackage:"計劃套餐",
            general:"常規管理",
            agent:"代理管理",
            addDevice:"添加設備",
            fakeNlp:"Nlp",
            fakeNlpSession:"Nlp會話",
            fakeNlpMessage:"Nlp消息",
        },
        table: {
            order_id: "排序",
            url: "鏈接地址",
        },
        field: {
            pid: "父權限",
            order_id: "排序",
            name: "名稱",
            url: "鏈接地址",
            status: "狀態",
            icon: "圖標",
        },
    },
    login: {
        validate: {
            required: {
                username: "請輸入用戶名",
                password: "輸輸入密碼",
                captcha: "請輸入驗證碼",
            }
        }
    },
    rule: {
        name:{
            title:"菜單設定",
        },
        table: {
            name: "名稱",
            status: "狀態",
            content: "內容",
            type: "類型",
            condition: "條件",
            path:'目录',
            icon:'圖標',
        },
        type: {
            1: "鏈接地址",
            2: "方法"
        },
        field: {
            name: "名稱",
            content: "內容",
            type: "類型",
            condition: "條件",
            status: "狀態",
            parent:"父級",
            path: "目錄",
            icon: "圖標",
            order: "排序",
            isMenu: "是否菜單",
        },
    },
    group: {
        name: {
            assign_rule: "規則分配",
            check_all: "選擇全部",
        },
        table: {
            users_count: "用戶數",
        },
        field: {
            users_count: "用戶數",
        },
    },
    member: {
        name: {
            reset_password: "重置密碼",
            search_user_name: "搜索用戶名"
        },
        table: {
            username: "用戶名",
            last_ip: "最後登錄IP"
        },
        field: {
            username: "用戶名",
            password: "密碼",
            repassword: "重復密碼",
            sex: "性別",
            birthday: "出生年月日",
        },
    },
    uploads: {
        table: {
            file_name: "文件名",
            file_md5: "文件Md5",
            file_type: "文件類型",
        },
        status: {
            0: "未知",
            1: "私有",
            2: "公開",
            3: "已刪除"
        }
    },
    dashboard: {
        name: {
            active_devices: "在線設備數量",
            queued_messages: "消息隊列",
            available_queue_slots: "可用隊列槽位",
            total_mb_sent: "總發送MB",
            sent_messages_last_hour: "一小時內消息數量",
            sent_messages_last_day: "一天內消息數量",
            sent_messages_last_month: "一月內消息數量",
            total_sent_messages: "總消息數量",
        },
        table: {
            
        },
        field: {
       
        },
        validate: {
            required: {
            }
        },
    },
    config: {
        name: {
            title: "基礎設置",
            base_config: "基礎配置",
            email_setting: "郵箱配置",
        },
        table: {
            
        },
        field: {

            site_name: "站點名稱",
            time_zone: "時區",
            logo: "系統圖標",
            copyright: "版權",
            service: "郵箱服務地址",
            port: "端口",
            username: "用戶名",
            password: "密碼",
            mail_verify: "郵箱協議",
            sender_mailbox: "發送者郵箱",
        },
        validate: {
            required: {
            }
        },
    },
    attachment: {
        name: {
            title: "附件管理",
        },
        table: {
            id: "ID",
            name: "名稱",
            type: "類型",
            size: "大小",
            storage: "存儲",
            create_at: "創建于",
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    userInfo: {
        name: {
            title: "個人中心",
            account: "賬號",
            password: "密碼",
            delete_account: "刪除賬號",
            update_profile: "更新信息",
            change_password: "修改密碼",
            balance_log:"收益記錄"
        },
        table: {
            reference:"關聯編號",
            payer:"付款人",
            amount:"入賬金額",
            pre_amount:"入賬前金額",
            new_amount:"入賬後金額",
            remark:"備註",
            create_at:"收益時間",
        },
        field: {
            full_name: "全名",
            email: "郵箱",
            language: "語言",
            time_zone: "時區",
            account_type: "賬號類型",
            address: "地址",
            city: "城市",
            postal_code: "郵政編碼",
            country: "國家",
            current_password: "當前密碼",
            new_password: "新密碼",
            confirm_password: "確認密碼",
            webhook:"接口回調鏈接",
            link:"專屬鏈接",
            copy:"複製",
            commission_rate:"分成比例",
            personal:"個人",
            bussiness:"商業",
            company_name:"公司名",
            
        },
        validate: {
            required: {
            }
        },
    },
    log: {
        name: {
            title: "操作日誌",

        },
        table: {
            id: "ID",
            title: "標題",
            action: "操作",
            create_at: "創建於",
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    order: {
        name: {
            title: "訂單列表",
            detailTitle:"訂單詳情"
        },
        table: {
            id: "ID",
            product: "產品",
            quantity: "數量",
            rate: "折扣",
            amount: "金額",
            pay_at: "支付於",
            status: "狀態",
            created_at: "創建於",
            payment_name: "支付方式"
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    userManage: {
        name: {
            title: "用戶管理",

        },
        table: {
            id: "ID",
            name: "用戶名",
            nick_name: "暱稱",
            group: "所屬組別",
            email: "郵箱",
            status: "狀態",
            register_time: "註冊時間",
            last_login_time: "最後登錄時間",
            referrer:"推薦人",
            
        },
        field: {
            name: "用戶名",
            nick_name: "暱稱",
            group: "所屬組別",
            email: "郵箱",
            status: "狀態",
            account: "登錄賬號",
            password: "密碼",
            promotion_authority:"推廣權限",
            commission_rate:"分成比例",
        },
        validate: {
            required: {
            }
        },
    },
    roleManage: {
        name: {
            title: "角色管理",

        },
        table: {
            id: "ID",
            name: "名稱",
            status: "狀態",
            create_at: "創建時間",
        },
        field: {

            name: "名稱",
            status: "狀態",
        },
        validate: {
            required: {
            }
        },
    },
    item: {
        name: {
            title: "套餐升級",
            select: "選擇",
            free: "免費",
            customer: "客製化",
            userProfile: "客戶資料",
            employeeAccount: "員工同時登入操作",
            marketingSms: "營銷群發訊息",
            automationRule: "自動化規則",
            devicesConnect: "設備連接",
            whatsapp: "WhatsApp 以外的所有讯息渠道",
            marketingData: "营销数据分析",
            app: "手机应用程式",
            facebook: "Facebook Lead Ads 整合",
            no_limit:"無上限",

        },
        table: {
          
        },
        field: {
          
        },
        validate: {
            required: {
            }
        },
    },
    product: {
        name: {
            title: "產品管理",

        },
        table: {
            id: "ID",
            name: "名稱",
            price_type: "價格類型",
            rent: "租金",
            order: "排序",
            status: "狀態",
            create_at: "創建於",
        },
        field: {
            name: "名稱",
            description: "描述",
            price_type: "價格類型",
            rent: "每月租金",
            order: "排序",
            status: "狀態",
            clientProfile: "客戶資料數量",
            input: "輸入",
            no_limit: "無限",
            employeeAccount:"額外員工賬號",
            marketingSms: "營銷群發消息數量",
            automationRule: "自動化規則數量",
            devicesConnect: "設備鏈接數量",
            marketingData: "营销数据分析",
            whatsapp:"WhatsApp咨詢及連接支援",
            app:"支持手機應用程序",
            shopify:"支持Shopify整合",
            permissions:"支持用戶角色及權限",
            api:"支持API鏈接",
            customerService:"優先客服咨詢",
            team:"支持團隊分配功能",
            hideContact:"支持隱藏聯絡人資料",
            bi: "商業智能(BI)整合",
            sla:"服務層級協議(SLA)",
            training: "專屬客戶經理及企業培訓",
        },
        validate: {
            required: {
            }
        },
    },
    history:{
        name: {
            title: "歷史記錄",
            send: "發送",

        },
        table: {
            id: "ID",
            user: "用戶",
            device: "設備",
            target: "目標",
            target_wid: "目標WID",
            reference_id: "關聯ID",
            priority: "優先級",
            message: "消息",
            create_at: "創建於",
        },
        field: {
    
            
        },
        validate: {
            required: {
            }
        },
    },
    device:{
        name: {
            title: "設備列表",
            connect_number: "添加新設備",
            view_more: "查看更多",
            pleaseSetNlp:"請先輸入Google Nlp的設定信息",
            customer:"用戶",
            plan:"計劃",
            alias:"設備別名",
            des:"描述（可選）",
            identify:"输入一个别名以帮助您识别它。",
            optionalDes:"关于新设备用途的可选说明，供您参考。",
            newDevice:"新增設備",
            next:"下一步",
        },
        table: {
            id: "ID",
            name: "名稱",
            status: "狀態",
            create_at: "創建於",
            is_robot:"打開NLP自動回復",
            plan: "計劃",
            msg:"文本消息",
            media_msg:"媒體消息",
            expire_day:"到期日",
        },
        field: {
            id: "ID",
            name: "名稱",
            number: "號碼",
            description: "描述",
            whatsapp: "WhatsApp ID",
            plan: "計劃",
            battery: "電量",
            status: "狀態",
            session_status: "會話狀態",
            whatsapp_version: "WhatsApp 版本",
            whatsapp_client: "WhatsApp 客戶端",
            platform: "平台",
            nlp_client_email:"Nlp郵箱",
            nlp_private_key:"Nlp私鑰",
            is_robot:"打開NLP自動回復",
            nlp_project_id:"Nlp項目ID",
            nlp_language_code:"Nlp語言",
            
        },
        validate: {
            required: {
            }
        },
    },
    book:{
        name: {
            title: "通訊錄",

        },
        table: {
            id: "ID",
            name: "名稱",
            type: "類型",
            email: "電郵",
            mobile: "移動電話",
            tel: "座機",
            fax: "傳真",
            create_at: "創建於",
            tag:"自定義標籤"
        },
        field: {
            name: "名稱",
            type: "類型",
            email: "電郵",
            mobile: "移動電話",
            tel: "座機",
            fax: "傳真",
            company: "公司名稱",
            address:"公司地址",
            tag:"自定義標籤"
            
        },
        validate: {
            required: {
            }
        },
    },
    payment:{
        name: {
            title: "支付方式",

        },
        table: {
            id: "ID",
            name: "名稱",
            status: "狀態",
            create_at: "創建於",
            code: "代碼",
            sort:"排序",
            description:"描述",
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    message:{
        name: {
            title: "發送信息",
            add_location:"添加地址",
            remove_location:"移除坐標",
            send:"發送消息",
            phone:"電話號碼",
            group:"群組",
            excelBatch:"Excel批量",
            maxLimit:"最多6000个字符。允许多行。如果附加了媒体文件，则消息文本可以为空。允许使用WhatsApp富文本语法。",
            drag:"拖動文件到此處",
            click:"或单击此处从磁盘上载文件（最大1MB）",
        },
        table: {
            
        },
        field: {
            device: "設備",
            target: "目標",
            phone: "電話號碼",
            priority: "優先級",
            deliver: "送達時間",
            reference_id: "關聯 ID",
            message: "消息",
            location_type: "地址類型",
            address:"地址",
            coordinates: "坐標",
            map: "地圖",
            lat: "經度",
            lon: "緯度",
            
        },
        validate: {
            required: {
            }
        },
    },
    contactGroup: {
        name: {
           
        },
        table: {
            id: "Id",
            group_name: "組名",
            username: "用戶名",
        },
        field: {
            group_name: "組名",
            bookList: "聯絡人列表",
        },
        validate: {
            required: {
            }
        },
    },
    async: {
        name: {
           title: "同步中...",
           asyncFailed:"同步失敗!",
           asyncFailedMessage: "同步已超時，你可以嘗試重新同步或稍後再試！",
           close: "關閉",
           again: "重試",
           asyncFailedTipsTitle: "系統有如下錯誤異常:",
           asyncFailedTips: "瀏覽器內核崩潰"
        },
        table: {
           
        },
        field: {
       
        },
        validate: {
            required: {
            }
        },
    },
    api_key: {
        name: {
            
        },
        table: {
            id: "ID",
            key: "密鑰",
            created_at: "創建於",
            updated_at: "更新於",
            name:"應用名稱"

        },
        field: {
            id: "ID",
            key: "密鑰",
            created_at: "創建於",
            updated_at: "更新於",
            name:"應用名稱"
        },
    },
    country: {
        name: {
            title:"國家"
        },
        table: {
            name:"名稱",
            code:"代碼",
            status:"狀態",
            create_at:"創建於",
        },
        field: {
            
        },
    },
    meal: {
        name: {
            title:"計劃套餐"
        },
        table: {
            name:"名稱",
            amountPerMonth:"月費",
            textMessageNumber:"文本消息數量",
            mediaMessageNumber:"媒體消息數量",
            queuedMessageNumber:"隊列消息數量",
            sort:"排序",
            status:"狀態",
            createAt:"創建於",
            description:"描述",
            whatsappNumber:"設備數量",
            limited:"有限",
            unlimited:"無限",
            messagePerMinute:"每分鐘消息數量",
            maxMediaSize:"最大媒體大小",
            apiAccess:"API 接入",
            webhook:"網絡回調",
        },
        field: {
            
        },
    },
    userAccount: {
        name: {
            title:"代理賬號",
            log:"日誌",
        },
        table: {
            id:"ID",
            agentName:"代理名字",
            agentEmail:"代理郵箱",
            balance:"賬戶餘額",
            commissionRate:"分成比例",
            createAt:"創建於",

        },
        field: {
            
        },
    },
    accountList: {
        name: {
            title:"收款日誌",
        },
        table: {
            referenceNumber:"流水號#",
            account:"交易用戶",
            amount:"入賬金額",
            preAmount:"入賬前金額",
            newAmount:"入賬後金額",
            remark:"備註",
            createAt:"創建於",
        },
        field: {
            
        },
    },
    apiKey: {
        name: {
            title:"應用管理",
        },
        table: {


        },
        field: {
            
        },
    },
    fastAnswer: {
        name: {
            title:"直答",
        },
        table: {
            response:"回答"

        },
        field: {
            
        },
    },
    chatBox:{
        name: {
            chatList:"聊天列表",
            clientInfo:"客戶信息",
            userInfo:"用戶信息",
            addRemark:"添加備註",
            send:"發送",
            placeholder:"搜索內容",
            online:"在線",
            offline:"離線",
            userName:"用戶名",
            mobile:"移動電話",
            address:"地址",
            companyName:"公司名字",
            remark:"備註",
            title:"標題",
        },
        table: {
            response:"Response",

        },
        field: {
            
        },
    },
    fakeNlp:{
      name: {
          title: "NLP 列錶",

      },
      table: {
          id: "ID",
          code: "問題編號",
          question: "問題",
          answer: "答案",
          data_type: "數據類型",
      },
      field: {
        id: "ID",
        code: "問題編號",
        question: "問題",
        answer: "答案",
        data_type: "數據類型",
      },
      validate: {
          required: {
          }
      },
  },
  fakeNlpSession:{
    name: {
        title: "NLP會話列錶",

    },
    table: {
        id: "ID",
        name: "姓名",
        phone: "電話",
    },
    field: {
      id: "ID",
      name: "姓名",
      phone: "電話",
    },
    validate: {
        required: {
        }
    },
},
fakeNlpMessage:{
  name: {
      title: "NLP消息列錶",

  },
  table: {
      id: "ID",
      content: "內容",
      create_at: "創建於",
      is_from_user: "是否用戶發送",
      session_id: "會話ID",
      name: "姓名",
      phone: "電話",
  },
  field: {
    id: "ID",
    content: "內容",
    create_at: "創建於",
    is_from_user: "是否用戶發送",
    session_id: "會話ID",
    name: "姓名",
    phone: "電話",
  },
  validate: {
      required: {
      }
  },
},
}

export default zhTW

import { Options, Vue } from "vue-class-component";
import { Menu, notification } from "ant-design-vue";

import { Nav as navApi } from "@/api/Nav";
import { LogoutOutlined, UserOutlined, DashboardOutlined, ControlOutlined, TeamOutlined, UploadOutlined, PhoneOutlined } from "@ant-design/icons-vue";

@Options({
  name: "Nav",
  components: {
    LogoutOutlined,
    UserOutlined,
    DashboardOutlined,
    ControlOutlined,
    TeamOutlined,
    UploadOutlined,
    PhoneOutlined,
    Menu,
    MenuItem: Menu.Item,
    MenuSub: Menu.SubMenu,
  },
  data() {
    return {
      navList: null,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      location.reload();
    },
    clickItem(item: any) {
      if (!item) return false;
      var path = item.key
      // path = path.replace("/","_");
      if (this.$router.hasRoute(path)) {
        this.$router.push({
          name: path,
        });
      } else {
        notification.warning({
          message: this.$t('public.name.no_page'),
        });
      }
    },
    get_user_nav() {
      let api = new navApi();
      api.user_nav().then((response: any) => {
        if (0 == parseInt(response.data.errno)) {
          this.navList = this.treeMenus(response.data.data,0);
        } else {
          this.navList = [];
        }
        // this.$store.commit("setMenus",this.navList);
        // console.log(this.navList)
      });
    },

    treeMenus( item:any, id:number) {
      var child = function(pareID:number){
      //先定义一个数组，用于存储所查到的子元素
          var childs = []
          //循环数组
          for(let i = 0; i<item.length;i++) {
          //如果数组其中一项的parentId等于传入的，说明这一项是传入的子元素，把他push进数组，然后重复递归自己找该项的子元素
              if(item[i].pid == pareID) {
                  item[i].children = child(item[i].id)
                  childs.push(item[i])
              }
          }
          //最后将查到的所有子元素返回
          return childs;
      }
      return child(id);
    }
  },
  mounted() {
    this.get_user_nav();
  },
})
export default class Nav extends Vue {}


import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, message } from "ant-design-vue";
import { Auth as cAuth } from "@/api/Auth";

@Options({
  name: "PersonalPassword",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    InputPassword: Input.Password,
  },
  data() {
    return {
      visible: false,
      data: {
        password: "",
        repassword: "",
      },
      validateRules: {
        password: [
          {
            required: true,
            message: this.$t("public.validate.required.password"),
            trigger: "blur",
          },
        ],
        repassword: [
          {
            required: true,
            message: this.$t("public.validate.required.repassword"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async show() {
      this._resetData();
      this.visible = true;
    },
    // send put
    send() {
      let api = new cAuth();
      this.$refs["member_form"]
        .validate()
        .then(() => {
          api
            .pasw({
              put_type: "password",
              password: this.data.password,
              repassword: this.data.repassword,
            })
            .then((response: any) => {
              if (0 == parseInt(response.data.errno)) {
                message.success(response.data.errmsg);
                this.visible = false;
                this.$store.dispatch("logout");
                location.reload();
              } else {
                message.error(response.data.errmsg);
              }
            });
        })
        .catch();
    },
    // reset data
    _resetData() {
      this.data = {
        password: "",
      };
    },
  },
})
export default class PersonalPassword extends Vue {}

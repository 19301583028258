import { Options, Vue } from "vue-class-component";
import { Row, Col, Form, Input, Image, Radio, message ,Button,Alert} from "ant-design-vue";
import { LoginOutlined, UserOutlined } from "@ant-design/icons-vue";
import { Captcha } from "@/api/Captcha";
import { Login as apiLogin } from "@/api/Login";
import zhTW from "ant-design-vue/es/locale/zh_TW";
import enUS from "ant-design-vue/es/locale/en_US";
@Options({
  name: "Login",
  components: {
    Row,
    Col,
    LoginOutlined,
    UserOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Button,
    Alert
  },
  data() {
    return {
      login: {
        email: "",
      },
      error:null,
      isLoad:false,
      captchaImg: "",
      captchaExpire: 0,
      language: "zhTW",
      validateRules: {
        email: [
          {
            required: true,
            message: this.$t("login.validate.required.username"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("login.validate.required.password"),
            trigger: "blur",
          },
        ]
      },
    };
  },
  methods: {    
    // send login message
    sendLogin() {
      
      this.$refs["refLogin"]
        .validate()
        .then(() => {
          this.isLoad  =true;
          this.error = null;
          const api = new apiLogin();
          api.recover(this.login).then((response: any) => {
            this.isLoad = false;
            if (0 == parseInt(response.data.errno)) {
                
            } else {
              this.error = response.data.errmsg;
              //message.error(response.data.errmsg);
              // this.getCaptcha();
            }
          });
        })
        .catch();
    },
    // check login statuc
    checkLogin() {
      
    },
  },
  mounted() {
    //this.checkLogin();
    this.language = this.$store.getters.getLanguage;
  },
})
export default class Login extends Vue { }
const enUS = {
    public: {
        name: {
            status: "State",
            hello: "Hello",
            login: "Login",
            logout: "Logout",
            username: "Username",
            email: "E-mail",
            password: "Password",
            confirm_password: "Confirm Password",
            reset_password: "Reset Password",
            captcha: "Captcha",
            more: "More",
            add: "Add",
            edit: "Edit",
            action: "Action",
            del: "Delete",
            yes: "Yes",
            no: "No",
            empty: "Empty",
            are_you_sure_delete: "Are you Sure Want To Delete This Item",
            action_cannot_resumed: "This Action Cannot Be Resumed",
            search: "Search",
            reset: "Reset",
            spread: "Spread",
            retract: "Retract",
            show: "Show",
            no_page: "No Page",
            service: "Service",
            phone_country_code: "Phone Country Code",
            name: "Name",
            phone: "Phone",
            sex: "Sex",
            country: "Coutry",
            address: "Address",
            birthday: "Birthday",
            remark: "Remark",
            personal: "Personal",
            enclosure: "Enclosure",
            logo: "Logo",
            download: "Download",
            open:"Open",
            detail:"Detail",
            normal:"Normal",
            disabled:"Disabled",
        },
        language: {
            zhTW: "Chinese (traditional)",
            enUS: "English",
            zhCN: "Chinese (simplified)",
        },
        status: {
            0: "Unknown",
            1: "Normal",
            2: "Disable",
            3: "Delete",
        },
        sex: {
            1: "Man",
            2: "Woman",
            3: "Unknown"
        },
        placeholder: {
            please_select: "Please Select",
        },
        table: {
            name: "Name",
            status: "State",
            action: "Action",
            content: "Content",
            created_at: "Create Time",
            image: "Image",
            email: "E-mail",
            order_id: "Order",
            sex: "Sex",
            last_ip: "Last Ip",
            phone_country_code: "Please Select Phone Country Code",
            phone: "Phone",
        },
        field: {
            name: "Name",
            username: "Username",
            content: "Content",
            phone_country_code: "Phone Country Code",
            phone: "Phone",
            email: "E-mail",
            order_id: "Order",
            portrait: "Portrait",
            status: "State",
            image: "Image",
            sex: "Sex",
        },
        validate: {
            required: {
                id: "Lack ID",
                username: "Please Entry User Name",
                password: "Please Entry Password",
                repassword: "Please Repeat password",
                name: "Please Entry Name",
                phone: "Please Entry Phone",
                email: "Please Entry E-mail",
                portrait: "Please Upload Portrait",
            },
            tips: {
                inconsistent_password: "Inconsistent password",
                length_should: "Length Should Be {min} To {max}"
            }
        }
    },
    language: {
        zhTW: "Chinese(Traditional)",
        enUS: "English",
        zhCN: "Chinese (simplified)",
    },
    nav: {
        name: {
            Dashboard: "Dashboard",
            Auth: "Auth",
            Rule: "Rule List",
            Group: "Group List",
            Nav: "Nav List",
            NavMenu: "Nav List",
            Member: "Member List",
            UploadManage: "Upload Manage",
            PhoneCountry: "Phone Country",
            Carousel: "Carousel",
            UserManage: "User Manage",
            AreaManage: "Area Manage",
            AreaList: "Area List",
            DriverManage: "Driver Manage",
            DriverList: "Driver List",
            DriverService: "Driver Service",
            DriverOrder: "Driver Order",
            CarRentalManage: "Car Rental Manage",
            CarRentalType: "Car Rental Type",
            CarRentalCar: "Car Rental Car",
            CarRentalOrder: "Car Rental Order",
            SaleCarManage: "Sale Car Manage",
            SaleCar: "Sale Car",
            SaleCarAppointment: "Sale Car Appointment",
            OilCardManage: "Oil Card Manage",
            OilCardType: "Oil Card Type",
            OilCardOrder: "Oil Card Apply List",
            FAQManage: "FAQ Manage",
            ProtocolManage: "Protocol Manage",
            General:"General",
            Setting:"Setting",
            Attachment:"Attachement",
            UserInfo: "User Info",
            OperatorLog: "Operator Log",
            OrderList: "Order List",
            Authority: "Authority Manage",
            RoleManage: "Role Manage",
            MenuSetting: "Menu Setting",
            AccountManage: "Account Manage",
            PackageManage: "Package Manage",
            ProductManage: "Product Manage",
            WhatsApp: "Whats App",
            History: "History",
            Devices: "Devices",
            NewMessage: "New Message",
            Books:"Books",
            Plugin: "Plugin",
            PaymentMethod: "Payment Method",
            customerService:"CS",
            FastAnswer:"Answer",
            Nlp:"Nlp",
            ContactGroup:"Contact Group",
            ApiKey:"App management",
            country: "Coutry",
            devicePackage:"Device Plan",
            general:"General",
            agent:"Agent Manage",
            addDevice:"Add Device",
            fakeNlp:"New Nlp",
            fakeNlpSession:"New Nlp Session",
            fakeNlpMessage:"New Nlp Message",
        },
        table: {
            order_id: "Order ID",
            url: "Url",
        },
        field: {
            pid: "PID",
            order_id: "Order ID",
            name: "Name",
            url: "Url",
            status: "State",
            icon: "Icon",
        },
    },
    login: {
        validate: {
            required: {
                username: "Please Entry Username",
                password: "Please Entry Password",
                captcha: "Please Entry Captcha",
            }
        }
    },
    rule: {
        name:{
            title:"Rule List",
        },
        table: {
            name: "Name",
            status: "Status",
            content: "Content",
            type: "Type",
            condition: "Condition",
            path: "Path",
            icon: "Icon",
        },
        type: {
            1: "Url",
            2: "Method"
        },
        field: {
            name: "Name",
            content: "Content",
            type: "Type",
            condition: "Condition",
            status: "State",
            parent:"Parent",
            path: "Path",
            icon: "Icon",
            order: "Order",
            isMenu: "Is Menu",
        },
    },
    group: {
        name: {
            assign_rule: "Assign Rule",
            check_all: "Check All",
        },
        table: {
            users_count: "Users Count",
        },
        field: {
            users_count: "Users Count",
        },
    },
    member: {
        name: {
            reset_password: "Reset Password",
            search_user_name: "Search User Name"
        },
        table: {
            username: "UserName",
            last_ip: "Last IP"
        },
        field: {
            username: "User Name",
            password: "Password",
            repassword: "Repeat password",
            sex: "Sex",
            birthday: "Birthday",
        },
        validate: {
            required: {
            }
        },
    },
    uploads: {
        table: {
            file_name: "File Name",
            file_md5: "File Md5",
            file_type: "File Type",
        },
        status: {
            0: "Unknown",
            1: "Private",
            2: "Public",
            3: "Delete"
        }
    },
    dashboard: {
        name: {
            active_devices: "Active Devices",
            queued_messages: "Queued Messages",
            available_queue_slots: "Available Queue Slots",
            total_mb_sent: "Total MB Sent",
            sent_messages_last_hour: "Sent Messages Last Hour",
            sent_messages_last_day: "Sent Messages Last Day",
            sent_messages_last_month: "Sent Messages Last Month",
            total_sent_messages: "Total Sent Messages",
        },
        table: {
            
        },
        field: {
       
        },
        validate: {
            required: {
            }
        },
    },
    config: {
        name: {
            title: "Setting",
            base_config: "Base Config",
            email_setting: "Email Setting",
        },
        table: {
            
        },
        field: {

            site_name: "Site Name",
            time_zone: "Time Zone",
            logo: "LOGO",
            copyright: "Copyright",
            service: "Serivce",
            port: "Port",
            username: "Username",
            password: "Password",
            mail_verify: "Mail Verify",
            sender_mailbox: "Sender Mailbox",
        },
        validate: {
            required: {
            }
        },
    },
    attachment: {
        name: {
            title: "Attachment Manage",
        },
        table: {
            id: "ID",
            name: "Name",
            type: "Type",
            size: "Size",
            storage: "Storage",
            create_at: "Create At",
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    userInfo: {
        name: {
            title: "User Info",
            account: "Account",
            password: "Password",
            delete_account: "Delete Account",
            update_profile: "Update Profile",
            change_password: "Change Password",
            balance_log:"Balance Log"
        },
        table: {
            reference:"Reference Number",
            payer:"Payer",
            amount:"Amount",
            pre_amount:"Pre Amount",
            new_amount:"New Amount",
            remark:"Remark",
            create_at:"Create At",
        },
        field: {
            full_name: "Full Name",
            email: "Email",
            language: "Language",
            time_zone: "Time Zone",
            account_type: "Account Type",
            address: "Address",
            city: "City",
            postal_code: "Postal Code",
            country: "Country",
            current_password: "Current Password",
            new_password: "New Password",
            confirm_password: "Confirm Password",
            webhook:"Webhook Url",
            link:"My Share Link",
            copy:"Copy",
            commission_rate:"Commission Rate",
            personal:"Personal",
            bussiness:"Bussiness",
            company_name:"Company Name",
            
            
        },
        validate: {
            required: {
            }
        },
    },
    log: {
        name: {
            title: "Log Manage",

        },
        table: {
            id: "ID",
            title: "Title",
            action: "Action",
            create_at: "Create At",
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    order: {
        name: {
            title: "Order Manage",
            detailTitle:"Order Detail"
        },
        table: {
            id: "ID",
            product: "Product",
            quantity: "Quantity",
            rate: "Rate",
            amount: "Amount",
            pay_at: "Pay At",
            status: "Status",
            created_at: "Created At",
            payment_name: "Pyament Name",
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    userManage: {
        name: {
            title: "User Manage",

        },
        table: {
            id: "ID",
            name: "Name",
            nick_name: "Nick Name",
            group: "Group",
            email: "Email",
            status: "Status",
            register_time: "Register Time",
            last_login_time: "Last Login Time",
            referrer:"Referrer",
        },
        field: {
            name: "Name",
            nick_name: "Nick Name",
            group: "Group",
            email: "Email",
            status: "Status",
            account: "Account",
            password: "Password",
            promotion_authority:"Promotion Authority",
            commission_rate:"Commission Rate",
        },
        validate: {
            required: {
            }
        },
    },
    roleManage: {
        name: {
            title: "Role Manage",

        },
        table: {
            id: "ID",
            name: "Name",
            status: "Status",
            create_at: "Create At",
        },
        field: {
            name: "Name",
            status: "Status",
            
        },
        validate: {
            required: {
            }
        },
    },
    item: {
        name: {
            title: "Select Package",
            select: "Select",
            free: "Free",
            customer: "Customer",
            userProfile: "User Profile",
            employeeAccount: "Employee Account",
            marketingSms: "Marketing SMS",
            automationRule: "Automation Rule",
            devicesConnect: "Device Connect",
            whatsapp: "All Message Channels",
            marketingData: "Marketing Data Analysis",
            app: "Mobile App",
            facebook: "Facebook Lead Ads Integration",
            no_limit:"No Limit",





        },
        table: {
          
        },
        field: {
          
        },
        validate: {
            required: {
            }
        },
    },
    product: {
        name: {
            title: "Product Manage",

        },
        table: {
            id: "ID",
            name: "Name",
            price_type: "Price Type",
            rent: "Rent",
            order: "Order",
            status: "Status",
            create_at: "Create At",
        },
        field: {
            name: "Name",
            description: "Description",
            price_type: "Price Type",
            rent: "Rent",
            order: "Order",
            status: "Status",
            clientProfile: "Client Profile Number",
            input: "Input",
            no_limit: "No Limit",
            employeeAccount:"Employee Account Number",
            marketingSms: "Marketing SMS Number",
            automationRule: "Automation Rule Number",
            devicesConnect: "Device Connect Number",
            marketingData: "Marketing Data Analysis",
            whatsapp:"WhatsApp Consulting And Connection Support",
            app:"Mobile app support",
            shopify:"Support Shopify Integration",
            permissions:"Support User Roles And Permissions",
            api: "Support Api",
            customerService:"Priority customer service consultation",
            team:"Support Team Assignment Function",
            hideContact:"Support Hide Contact",
            bi:"Business Intelligence (BI) Integration",
            sla:"Service Level Agreement (SLA)",
            training: "Exclusive customer manager and enterprise training",
        },
        validate: {
            required: {
            }
        },
    },
    history:{
        name: {
            title: "History",
            send: "Send",

        },
        table: {
            id: "ID",
            user: "User",
            device: "Device",
            target: "Target",
            target_wid: "Target WID",
            reference_id: "Reference ID",
            priority: "Priority",
            message: "Message",
            create_at: "Create At",
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    device:{
        name: {
            title: "Devices",
            connect_number: "Contact Number",
            view_more: "View More",
            pleaseSetNlp:"Please set nlp client email and private key",
            customer:"Customer",
            plan:"Plan",
            alias:"Alias",
            des:"Description (optional)",
            identify:"Enter an alias that helps you identify it.",
            optionalDes:"Optional description for your reference about the purpose of the new device.",
            newDevice:"New Device",
            next:"Next Step",
        },
        table: {
            id: "ID",
            name: "Name",
            status: "Status",
            create_at: "Create At",
            is_robot:"Turn On Nlp Robot",
            plan: "Plan",
            msg:"Text Message",
            media_msg:"Media Message",
            expire_day:"Expire Day",
        },
        field: {
            id: "ID",
            name: "Name",
            number: "Number",
            description: "Description",
            whatsapp: "WhatsApp ID",
            plan: "Plan",
            battery: "Battery",
            status: "Status",
            session_status: "Session Status",
            whatsapp_version: "WhatsApp Version",
            whatsapp_client: "WhatsApp Client",
            platform: "Platform",
            nlp_client_email:"Nlp Client Email",
            nlp_private_key:"Nlp Private Key",
            is_robot:"Turn On Nlp Robot",
            nlp_project_id:"Nlp Project Id",
            nlp_language_code:"Nlp Language Code",
            
        },
        validate: {
            required: {
            }
        },
    },
    book:{
        name: {
            title: "Contact List",

        },
        table: {
            id: "ID",
            name: "Name",
            type: "Type",
            email: "Email",
            mobile: "Mobile",
            tel: "Tel",
            fax: "Fax",
            create_at: "Create At",
            tag:"Tag"
        },
        field: {
            name: "Name",
            type: "Type",
            email: "Email",
            mobile: "Mobile",
            tel: "Tel",
            fax: "Fax",
            company: "Company Name",
            address:"Company Address",
            tag:"Tag"
        },
        validate: {
            required: {
            }
        },
    },
    payment:{
        name: {
            title: "Payment",

        },
        table: {
            id: "ID",
            name: "Name",
            status: "Status",
            create_at: "Create At",
            code: "Code",
            sort: "Sort",
            description: "Description",
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    message:{
        name: {
            title: "Send Message",
            add_location:"Add Location",
            remove_location:"Remove Location",
            send:"Send message",
            phone:"Phone",
            group:"Group",
            excelBatch:"Excel Batch",
            maxLimit:"Maximum 6000 characters. Multiple lines are allowed.Message text can be empty if there is a media file attached.WhatsApp rich text syntax is allowed.",
            drag:"Drag & Drop file",
            click:"Or click here to upload a file from disk (maximum of 1MB)",
        },
        table: {
            
        },
        field: {
            device: "Device",
            target: "Target",
            phone: "Phone Number",
            priority: "Priority",
            deliver: "Deliver At",
            reference_id: "Reference ID",
            message: "Message",
            location_type: "Location Type",
            address:"Address",
            coordinates: "Coordinates",
            map: "Map View",
            lat: "Latitude",
            lon: "Longitude",
            
        },
        validate: {
            required: {
            }
        },
    },
    contactGroup: {
        name: {
           
        },
        table: {
            id: "Id",
            group_name: "Group Name",
            username: "User Name",
        },
        field: {
            group_name: "Group Name",
            bookList: "Contact List",
        },
        validate: {
            required: {
            }
        },
    },
    async: {
        name: {
           title: "Synchronizing...",
           asyncFailed:"Synchronize failed!",
           asyncFailedMessage: "Synchronize had been timeout,you could try again or maybe try again later!",
           close: "Close",
           again: "Try again",
           asyncFailedTipsTitle: "The content you submitted has the following error:",
           asyncFailedTips: "Browser kernel crash"
        },
        table: {
           
        },
        field: {
       
        },
        validate: {
            required: {
            }
        },
    },
    api_key: {
        name: {
            
        },
        table: {
            id: "ID",
            key: "Key",
            created_at: "Created At",
            updated_at: "Updated At",
            name:"Name"

        },
        field: {
            id: "ID",
            key: "Key",
            created_at: "Created At",
            updated_at: "Updated At",
            name:"Name"
        },
    },
    country: {
        name: {
            title:"Country"
        },
        table: {
            name:"Name",
            code:"Code",
            status:"Status",
            create_at:"Create At",
        },
        field: {
            
        },
    },
    meal: {
        name: {
            title:"Plan"
        },
        table: {
            name:"Name",
            amountPerMonth:"Amount Per Month",
            textMessageNumber:"Text Message Number",
            mediaMessageNumber:"Media Message Number",
            queuedMessageNumber:"Queued Message Number",
            sort:"Sort",
            status:"Status",
            createAt:"Create At",
            description:"Description",
            whatsappNumber:"Device Number",
            limited:"Limited",
            unlimited:"Unlimited",
            messagePerMinute:"Message Per Minute",
            maxMediaSize:"Max Media Size",
            apiAccess:"API Access",
            webhook:"Webhook",
        },
        field: {
            
        },
    },
    userAccount: {
        name: {
            title:"Agent Account",
            log:"Log",
        },
        table: {
            id:"ID",
            agentName:"Agent Name",
            agentEmail:"Agent Email",
            balance:"Balance",
            commissionRate:"Commission Rate",
            createAt:"Create At",

        },
        field: {
            
        },
    },
    accountList: {
        name: {
            title:"Balance Log",
        },
        table: {
            referenceNumber:"Reference Number#",
            account:"Account",
            amount:"Amount",
            preAmount:"Pre Amount",
            newAmount:"New Amount",
            remark:"Remark",
            createAt:"Create At",

        },
        field: {
            
        },
    },
    apiKey: {
        name: {
            title:"App management",
        },
        table: {


        },
        field: {
            
        },
    },
    fastAnswer: {
        name: {
            title:"Fast Answer",
        },
        table: {
            response:"Response",

        },
        field: {
            
        },
    },
    chatBox:{
        name: {
            chatList:"Chat List",
            clientInfo:"Client Info",
            userInfo:"User Info",
            addRemark:"Add Remark",
            send:"Send",
            placeholder:"Search Content",
            online:"Online",
            offline:"Offline",
            userName:"User Name",
            mobile:"Mobile",
            address:"Address",
            companyName:"Company Name",
            remark:"Remark",
            title:"Title",
        },
        table: {
            response:"Response",

        },
        field: {
            
        },
    },
    fakeNlp:{
      name: {
          title: "NLP List",

      },
      table: {
          id: "ID",
          code: "Code",
          question: "Question",
          answer: "Answer",
          data_type: "Data Type",
      },
      field: {
        id: "ID",
        code: "Code",
        question: "Question",
        answer: "Answer",
        data_type: "Data Type",
      },
      validate: {
          required: {
          }
      },
  },
  fakeNlpSession:{
    name: {
        title: "NLP Session List",

    },
    table: {
        id: "ID",
        name: "Name",
        phone: "Phone",
    },
    field: {
      id: "ID",
      name: "Name",
      phone: "Phone",
    },
    validate: {
        required: {
        }
    },
},
fakeNlpMessage:{
  name: {
      title: "NLP Message List",

  },
  table: {
      id: "ID",
      content: "Content",
      create_at: "Create At",
      is_from_user: "Is From User",
      session_id: "Session Id",
      name: "Name",
      phone: "Phone",
  },
  field: {
    id: "ID",
    content: "Content",
    create_at: "Create At",
    is_from_user: "Is From User",
    session_id: "Session Id",
    name: "Name",
    phone: "Phone",
  },
  validate: {
      required: {
      }
  },
},
}

export default enUS
/**
 * @Auther    william
 * @Name      state action function
 * @Note      
 * @Time      2021/04/13 16:10
 */

import {ActionTree, Commit} from "vuex"
import {State} from "@/store/state"
let actions: ActionTree<State, any> = {
    // logout function
    logout( {commit} ): any {
        commit("logout")
    },
    socket( {commit} ): any{
        commit("connectSocket")
        
    },
}

export default actions
import { Options, Vue } from "vue-class-component";
import { Login as apiLogin } from "@/api/Login";
import { Layout, Row, Col, Dropdown, Button, Menu, Avatar } from "ant-design-vue";
import { LogoutOutlined,UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons-vue";
import Nav from "@/components/Nav.vue";
import PersonalEdit from "@/components/Home/PersonalEdit.vue"
import PersonalPassword from "@/components/Home/PersonalPassword.vue"
import LanguageButton from "@/components/Common/LanguageButton"

@Options({
  name: "Home",
  components: {
    Nav,
    Row,
    Col,
    Layout,
    Dropdown,
    Menu,
    MenuItem: Menu.Item,
    Avatar,
    Button,
    LayoutSider: Layout.Sider,
    LayoutHeader: Layout.Header,
    LayoutContent: Layout.Content,
    LayoutFooter: Layout.Footer,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    LogoutOutlined,
    UserOutlined,
    PersonalEdit,
    PersonalPassword,
    LanguageButton,
  },
  data() {
    return {
      collapsed:false,
      avatar:require("@/assets/img/user-avatar.png"),
      nickname:'',
    };
  },
  methods: {
    // check login statuc
    logout() {
      this.$store.dispatch("logout");
      // location.href='#/login';
      this.$router.go({ path: "login" });
    },
    personal() {
      this.$refs.refPersonalEdit.show();
    },
    reset_password() {
      this.$refs.refPasswordEdit.show();
    },
    clickItem(item: any) {
      if (!item) return false;
      switch (item.key) {
        case "logout":
          this.logout();
          break;
      }
    },
    checkLogin(){
      if (this.$store.getters.getToken == "") {
         location.href='/login';
      }
    }
  },
  mounted() {
    this.checkLogin();
    var user = this.$store.getters.getUser;
    // this.nickname = user.nickname
   
  },
  created(){
    this.checkLogin();
  }
})
export default class Home extends Vue {}
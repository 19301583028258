/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import store from "@/store"
import { default as axios, AxiosInstance } from "axios";
let httpUrl = store.getters.getDomain
// if (process.env.NODE_ENV === 'production') {
//     httpUrl = store.getters.getDomain
// } else {
//     httpUrl = store.getters.proDomain
// }

const apiUrl = httpUrl + "/api/"
const config = {
    httpUrl: httpUrl,
    baseURL: apiUrl,
    headers: {
        'Access-Control-Allow-Origin':"*",
        "Content-Type": "application/json; charset=UTF-8",
    },
    validateStatus(status: number | null) {
        if (null === status) return false
        return (status >= 200 && status < 300) || (status >= 400 && status < 500)
    }
}
const runAxios: AxiosInstance = axios.create(config)
runAxios.interceptors.request.use(
    function (config: any) {
        // TODO: Do something before request is sent
        return config;
    },
    function (error: any) {
        // TODO: Do something with request error
        return Promise.reject(error);
    }
)
// Add a response interceptor
runAxios.interceptors.response.use(
    function (response: any) {
        // TODO: Do something with response data
        return response;
    },
    function (error: any) {
        // TODO: Do something with response error
        return Promise.reject(error);
    }
)

export const Axios = runAxios

export class Base {
    constructor() {
        Axios.defaults.headers["ocrs-token"] = store.getters.getToken
    }
    path = ""
    get(param: any = {}): any {
        return Axios.get(this.path, { params: param })
    }
    post(param: any): any {
        return Axios.post(this.path, param)
    }
    put(param: any): any {
        return Axios.put(this.path, param)
    }
    delete(param: any): any {
        return Axios.delete(this.path, { params: param })
    }
}
/**
 * @Auther    william
 * @Name      state define file
 * @Note      
 * @Time      2021/04/13 16:53
 */

export interface State {
    systemTag: string
    language: string
    theme: string
    logo: string
    copyRight: string
    token: string
    noImage: string
    username: string
    portrait: string
    lastIp: string
    lastTime: number
    user:object
    menus:object,
    proDomain:string,
    devDomain:string,
    socketIo:object,
    message:object,
    agentCode:string,
    loadding:boolean,
}

let state: State = {
    systemTag: "crm",
    language: "zhTW",
    logo: "",
    theme: "dark",
    copyRight: "Copyright © 2021",
    token: "",
    noImage: require("@/assets/img/no-img-small.png"),
    username: "",
    portrait: "",
    lastIp: "",
    lastTime: 0,
    user: {},
    menus:{},
    socketIo:{},
    message:{},
    loadding:false,
    agentCode:'',
    // proDomain:"http://58.64.142.239:20116",
    proDomain:"https://imbackend.apisocket.io",
    devDomain:"http://127.0.0.1:8360", //   http://192.168.1.32:8360
    // devDomain:"https://im.apisocket.io", //   http://192.168.1.32:8360
}

export default state
import { Base, Axios } from "@/api/Base"

export class Auth extends Base {
    path = "auth/user"
    groups(params:any): any {
        return Axios.get("auth/group", { params: params })
    } 
    account(params:any): any {
        return Axios.get("account/index", { params: params })
    } 
    update(params:any): any {
        return Axios.post("account/update", params)
    } 
    pasw(params:any): any {
        return Axios.post("account/pasw", params)
    } 
    items(params:any): any {
        return Axios.get("user/items", { params: params })
    }
    proceeds(params:any): any {
        return Axios.get("account/proceeds", { params: params })
    }
}
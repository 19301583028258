
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, DatePicker, message } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { Login as loginApi } from "@/api/Login";
import { Personal as personalApi } from "@/api/Personal";

import UploadImage from "@/components/Common/UploadImage";
import moment from "moment";

@Options({
  name: "PersonalEdit",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    InputPassword: Input.Password,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    LoadingOutlined,
    PlusOutlined,
    UploadImage,
  },
  data() {
    return {
      visible: false,
      upLoading: false,
      portraitShow: "",
      data: {
        name: "",
        sex: 3,
        phone_country_code: "",
        phone: "",
        email: "",
        portrait: "",
        birthday: "",
        status: 1,
      },
      validateRules: {
        name: [
          {
            required: true,
            message: this.$t("public.validate.required.name"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("public.validate.required.email"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async show() {
      this._resetData();
      let api = new loginApi();
      await api.get().then((res: any) => {
        if (parseInt(res.data.code) == 1) {
          this.data = res.data.data;
          this.data.birthday = moment(res.data.data.birthday * 1000);
        }
      });
      this.visible = true;
    },
    // send put
    send() {
      let api = new personalApi();
      let param = { ...this.data };
      delete param.token;
      if (param.birthday) {
        param.birthday = param.birthday.unix();
      }
      this.$refs["member_form"]
        .validate()
        .then(() => {
          param.put_type = "message";
          api.put(param).then((response: any) => {
            if (1 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$store.commit("setName", param.name);
              this.$store.commit("setPortrait", param.portrait);
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch();
    },
    // reset data
    _resetData() {
      this.data = {
        name: "",
        sex: 3,
        phone_country_code: "",
        phone: "",
        email: "",
        portrait: "",
        birthday: "",
        status: 1,
      };
    },
    _get_new_image_url(value: string) {
      this.data.portrait = value;
    },
  },
})
export default class PersonalEdit extends Vue {}

const zhTW = {
    public: {
        name: {
            status: "状态",
            login: "登录",
            logout: "登出",
            hello: "你好",
            username: "用户名",
            email: "E-mail",
            password: "密码",
            confirm_password: "确认密码",
            reset_password: "重置密码",
            captcha: "验证码",
            more: "更多",
            action: "操作",
            add: "添加",
            edit: "编辑",
            del: "删除",
            yes: "是",
            no: "否",
            empty: "空",
            are_you_sure_delete: "你确定需要删除该信息吗",
            action_cannot_resumed: "该操作将无法恢复",
            search: "搜索",
            reset: "重置",
            spread: "展开",
            retract: "收回",
            show: "展示",
            no_page: "无页面",
            service: "服务商",
            phone_country_code: "电话国家代码",
            name: "名称",
            phone: "电话",
            sex: "性别",
            country: "国家",
            address: "地址",
            birthday: "出生年月日",
            remark: "备注",
            personal: "个人信息",
            enclosure: "附件",
            logo: "系统图标",
            download:"下载",
            open:"打开",
            detail:"详情",
            normal:"正常",
            disabled:"禁用",
        },
        
        language: {
            zhTW: "中文(繁体)",
            enUS: "英文",
            zhCN: "中文(简体)",
        },
        status: {
            0: "未知",
            1: "正常",
            2: "禁用",
            3: "已删除",
        },
        sex: {
            1: "男",
            2: "女",
            3: "未知"
        },
        placeholder: {
            please_select: "请选择",
        },
        table: {
            name: "名称",
            status: "状态",
            action: "操作",
            content: "内容",
            created_at: "创建时间",
            image: "图像",
            email: "E-mail",
            order_id: "排序",
            sex: "性别",
            last_ip: "最后登录IP",
            phone: "电话",
        },
        field: {
            name: "名称",
            username: "用户名",
            content: "内容",
            phone_country_code: "电话国家代码",
            phone: "电话",
            email: "E-mail",
            order_id: "排序",
            portrait: "头像",
            status: "状态",
            image: "图像",
            sex: "性别",
        },
        validate: {
            required: {
                id: "缺少ID",
                username: "请输入用户名",
                password: "请输入密码",
                repassword: "请重复密码",
                name: "请输入名称",
                phone: "请输入电话",
                email: "请输入E-mail",
                portrait: "请上传头像",
                phone_country_code: "请选择电话国家代码",
            },
            tips: {
                inconsistent_password: "密码不一致",
                length_should: "长度应为{min}至{max}"
            }
        }
    },
    language: {
        zhTW: "中文（繁体）",
        enUS: "英语",
        zhCN: "中文(简体)",
    },
    nav: {
        name: {
            Dashboard: "仪表盘",
            Auth: "权限",
            Rule: "规则列表",
            Group: "角色列表",
            Nav: "菜单列表",
            NavMenu: "菜单列表",
            Member: "成员列表",
            UploadManage: "上传管理",
            PhoneCountry: "电话国家代码",
            Carousel: "轮播图",
            UserManage: "用户管理",
            AreaManage: "区域管理",
            AreaList: "区域列表",
            DriverManage: "司机管理",
            DriverList: "司机列表",
            DriverService: "服务商列表",
            DriverOrder: "司机订单",
            CarRentalManage: "租车管理",
            CarRentalType: "租车类型",
            CarRentalCar: "租车车辆类型",
            CarRentalOrder: "租车订单",
            SaleCarManage: "销售车辆管理",
            SaleCar: "销售车辆",
            SaleCarAppointment: "销售预约",
            OilCardManage: "油卡管理",
            OilCardType: "油卡类型",
            OilCardOrder: "油卡申请列表",
            FAQManage: "FAQ管理",
            ProtocolManage: "协议管理",
            General:"基础信息",
            Setting:"基础设置",
            Attachment:"附件管理",
            UserInfo: "个人中心",
            OperatorLog: "操作日志",
            OrderList: "订单列表",
            Authority: "权限管理",
            RoleManage: "角色管理",
            MenuSetting: "菜单设置",
            AccountManage: "账号管理",
            PackageManage: "套餐升级",
            ProductManage: "产品管理",
            WhatsApp: "Whats App",
            History: "历史记录",
            Devices: "设备列表",
            NewMessage: "新信息",
            Books:"联络人",
            Plugin: "插件管理",
            PaymentMethod: "支付方式",
            customerService:"客服界面",
            FastAnswer:"直答",
            Nlp:"自动对答管理",
            ContactGroup:"联络人分组",
            ApiKey: "应用管理",
            country:"国家",
            devicePackage:"计划套餐",
            general:"常规管理",
            agent:"代理管理",
            addDevice:"添加设备",
            fakeNlp:"Nlp",
            fakeNlpSession:"Nlp会话",
            fakeNlpMessage:"Nlp消息",
        },
        table: {
            order_id: "排序",
            url: "链接地址",
        },
        field: {
            pid: "父权限",
            order_id: "排序",
            name: "名称",
            url: "链接地址",
            status: "状态",
            icon: "图标",
        },
    },
    login: {
        validate: {
            required: {
                username: "请输入用户名",
                password: "输输入密码",
                captcha: "请输入验证码",
            }
        }
    },
    rule: {
        name:{
            title:"菜单设定",
        },
        table: {
            name: "名称",
            status: "状态",
            content: "内容",
            type: "类型",
            condition: "条件",
            path:'目录',
            icon:'图标',
        },
        type: {
            1: "链接地址",
            2: "方法"
        },
        field: {
            name: "名称",
            content: "内容",
            type: "类型",
            condition: "条件",
            status: "状态",
            parent:"父级",
            path: "目录",
            icon: "图标",
            order: "排序",
            isMenu: "是否菜单",
        },
    },
    group: {
        name: {
            assign_rule: "规则分配",
            check_all: "选择全部",
        },
        table: {
            users_count: "用户数",
        },
        field: {
            users_count: "用户数",
        },
    },
    member: {
        name: {
            reset_password: "重置密码",
            search_user_name: "搜索用户名"
        },
        table: {
            username: "用户名",
            last_ip: "最后登录IP"
        },
        field: {
            username: "用户名",
            password: "密码",
            repassword: "重复密码",
            sex: "性别",
            birthday: "出生年月日",
        },
    },
    uploads: {
        table: {
            file_name: "文件名",
            file_md5: "文件Md5",
            file_type: "文件类型",
        },
        status: {
            0: "未知",
            1: "私有",
            2: "公开",
            3: "已删除"
        }
    },
    dashboard: {
        name: {
            active_devices: "在线设备数量",
            queued_messages: "消息队列",
            available_queue_slots: "可用队列槽位",
            total_mb_sent: "总发送MB",
            sent_messages_last_hour: "一小时内消息数量",
            sent_messages_last_day: "一天内消息数量",
            sent_messages_last_month: "一月内消息数量",
            total_sent_messages: "总消息数量",
        },
        table: {
            
        },
        field: {
       
        },
        validate: {
            required: {
            }
        },
    },
    config: {
        name: {
            title: "基础设置",
            base_config: "基础配置",
            email_setting: "邮箱配置",
        },
        table: {
            
        },
        field: {

            site_name: "站点名称",
            time_zone: "时区",
            logo: "系统图标",
            copyright: "版权",
            service: "邮箱服务地址",
            port: "端口",
            username: "用户名",
            password: "密码",
            mail_verify: "邮箱协议",
            sender_mailbox: "发送者邮箱",
        },
        validate: {
            required: {
            }
        },
    },
    attachment: {
        name: {
            title: "附件管理",
        },
        table: {
            id: "ID",
            name: "名称",
            type: "类型",
            size: "大小",
            storage: "存储",
            create_at: "创建于",
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    userInfo: {
        name: {
            title: "个人中心",
            account: "账号",
            password: "密码",
            delete_account: "删除账号",
            update_profile: "更新信息",
            change_password: "修改密码",
            balance_log:"收益记录"
        },
        table: {
            reference:"关联编号",
            payer:"付款人",
            amount:"入账金额",
            pre_amount:"入账前金额",
            new_amount:"入账后金额",
            remark:"备注",
            create_at:"收益时间",
        },
        field: {
            full_name: "全名",
            email: "邮箱",
            language: "语言",
            time_zone: "时区",
            account_type: "账号类型",
            address: "地址",
            city: "城市",
            postal_code: "邮政编码",
            country: "国家",
            current_password: "当前密码",
            new_password: "新密码",
            confirm_password: "确认密码",
            webhook:"接口回调链接",
            link:"专属链接",
            copy:"复制",
            commission_rate:"分成比例",
            personal:"个人",
            bussiness:"商业",
            company_name:"公司名",
            
        },
        validate: {
            required: {
            }
        },
    },
    log: {
        name: {
            title: "操作日志",

        },
        table: {
            id: "ID",
            title: "标题",
            action: "操作",
            create_at: "创建于",
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    order: {
        name: {
            title: "订单列表",
            detailTitle:"订单详情"
        },
        table: {
            id: "ID",
            product: "产品",
            quantity: "数量",
            rate: "折扣",
            amount: "金额",
            pay_at: "支付于",
            status: "状态",
            created_at: "创建于",
            payment_name: "支付方式"
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    userManage: {
        name: {
            title: "用户管理",

        },
        table: {
            id: "ID",
            name: "用户名",
            nick_name: "暱称",
            group: "所属组别",
            email: "邮箱",
            status: "状态",
            register_time: "注册时间",
            last_login_time: "最后登录时间",
            referrer:"推荐人",
            
        },
        field: {
            name: "用户名",
            nick_name: "暱称",
            group: "所属组别",
            email: "邮箱",
            status: "状态",
            account: "登录账号",
            password: "密码",
            promotion_authority:"推广权限",
            commission_rate:"分成比例",
        },
        validate: {
            required: {
            }
        },
    },
    roleManage: {
        name: {
            title: "角色管理",

        },
        table: {
            id: "ID",
            name: "名称",
            status: "状态",
            create_at: "创建时间",
        },
        field: {

            name: "名称",
            status: "状态",
        },
        validate: {
            required: {
            }
        },
    },
    item: {
        name: {
            title: "套餐升级",
            select: "选择",
            free: "免费",
            customer: "客制化",
            userProfile: "客户资料",
            employeeAccount: "员工同时登入操作",
            marketingSms: "营销群发讯息",
            automationRule: "自动化规则",
            devicesConnect: "设备连接",
            whatsapp: "WhatsApp 以外的所有讯息渠道",
            marketingData: "营销数据分析",
            app: "手机应用程式",
            facebook: "Facebook Lead Ads 整合",
            no_limit:"无上限",

        },
        table: {
          
        },
        field: {
          
        },
        validate: {
            required: {
            }
        },
    },
    product: {
        name: {
            title: "产品管理",

        },
        table: {
            id: "ID",
            name: "名称",
            price_type: "价格类型",
            rent: "租金",
            order: "排序",
            status: "状态",
            create_at: "创建于",
        },
        field: {
            name: "名称",
            description: "描述",
            price_type: "价格类型",
            rent: "每月租金",
            order: "排序",
            status: "状态",
            clientProfile: "客户资料数量",
            input: "输入",
            no_limit: "无限",
            employeeAccount:"额外员工账号",
            marketingSms: "营销群发消息数量",
            automationRule: "自动化规则数量",
            devicesConnect: "设备链接数量",
            marketingData: "营销数据分析",
            whatsapp:"WhatsApp咨询及连接支援",
            app:"支持手机应用程序",
            shopify:"支持Shopify整合",
            permissions:"支持用户角色及权限",
            api:"支持API链接",
            customerService:"优先客服咨询",
            team:"支持团队分配功能",
            hideContact:"支持隐藏联络人资料",
            bi: "商业智能(BI)整合",
            sla:"服务层级协议(SLA)",
            training: "专属客户经理及企业培训",
        },
        validate: {
            required: {
            }
        },
    },
    history:{
        name: {
            title: "历史记录",
            send: "发送",

        },
        table: {
            id: "ID",
            user: "用户",
            device: "设备",
            target: "目标",
            target_wid: "目标WID",
            reference_id: "关联ID",
            priority: "优先级",
            message: "消息",
            create_at: "创建于",
        },
        field: {
    
            
        },
        validate: {
            required: {
            }
        },
    },
    device:{
        name: {
            title: "设备列表",
            connect_number: "添加新设备",
            view_more: "查看更多",
            pleaseSetNlp:"请先输入Google Nlp的设定信息",
            customer:"用户",
            plan:"计划",
            alias:"设备别名",
            des:"描述（可选）",
            identify:"输入一个别名以帮助您识别它。",
            optionalDes:"关于新设备用途的可选说明，供您参考。",
            newDevice:"新增设备",
            next:"下一步",
        },
        table: {
            id: "ID",
            name: "名称",
            status: "状态",
            create_at: "创建于",
            is_robot:"打开NLP自动回复",
            plan: "计划",
            msg:"文本消息",
            media_msg:"媒体消息",
            expire_day:"到期日",
        },
        field: {
            id: "ID",
            name: "名称",
            number: "号码",
            description: "描述",
            whatsapp: "WhatsApp ID",
            plan: "计划",
            battery: "电量",
            status: "状态",
            session_status: "会话状态",
            whatsapp_version: "WhatsApp 版本",
            whatsapp_client: "WhatsApp 客户端",
            platform: "平台",
            nlp_client_email:"Nlp邮箱",
            nlp_private_key:"Nlp私钥",
            is_robot:"打开NLP自动回复",
            nlp_project_id:"Nlp项目ID",
            nlp_language_code:"Nlp语言",
            
        },
        validate: {
            required: {
            }
        },
    },
    book:{
        name: {
            title: "通讯录",

        },
        table: {
            id: "ID",
            name: "名称",
            type: "类型",
            email: "电邮",
            mobile: "移动电话",
            tel: "座机",
            fax: "传真",
            create_at: "创建于",
            tag:"自定义标籤"
        },
        field: {
            name: "名称",
            type: "类型",
            email: "电邮",
            mobile: "移动电话",
            tel: "座机",
            fax: "传真",
            company: "公司名称",
            address:"公司地址",
            tag:"自定义标籤"
            
        },
        validate: {
            required: {
            }
        },
    },
    payment:{
        name: {
            title: "支付方式",

        },
        table: {
            id: "ID",
            name: "名称",
            status: "状态",
            create_at: "创建于",
            code: "代码",
            sort:"排序",
            description:"描述",
        },
        field: {

            
        },
        validate: {
            required: {
            }
        },
    },
    message:{
        name: {
            title: "发送信息",
            add_location:"添加地址",
            remove_location:"移除坐标",
            send:"发送消息",
            phone:"电话号码",
            group:"群组",
            excelBatch:"Excel批量",
            maxLimit:"最多6000个字符。允许多行。如果附加了媒体文件，则消息文本可以为空。允许使用WhatsApp富文本语法。",
            drag:"拖动文件到此处",
            click:"或单击此处从磁盘上载文件（最大1MB）",
        },
        table: {
            
        },
        field: {
            device: "设备",
            target: "目标",
            phone: "电话号码",
            priority: "优先级",
            deliver: "送达时间",
            reference_id: "关联 ID",
            message: "消息",
            location_type: "地址类型",
            address:"地址",
            coordinates: "坐标",
            map: "地图",
            lat: "经度",
            lon: "纬度",
            
        },
        validate: {
            required: {
            }
        },
    },
    contactGroup: {
        name: {
           
        },
        table: {
            id: "Id",
            group_name: "组名",
            username: "用户名",
        },
        field: {
            group_name: "组名",
            bookList: "联络人列表",
        },
        validate: {
            required: {
            }
        },
    },
    async: {
        name: {
           title: "同步中...",
           asyncFailed:"同步失败!",
           asyncFailedMessage: "同步已超时，你可以尝试重新同步或稍后再试！",
           close: "关闭",
           again: "重试",
           asyncFailedTipsTitle: "系统有如下错误异常:",
           asyncFailedTips: "浏览器内核崩溃"
        },
        table: {
           
        },
        field: {
       
        },
        validate: {
            required: {
            }
        },
    },
    api_key: {
        name: {
            
        },
        table: {
            id: "ID",
            key: "密钥",
            created_at: "创建于",
            updated_at: "更新于",
            name:"应用名称",

        },
        field: {
            id: "ID",
            key: "密钥",
            created_at: "创建于",
            updated_at: "更新于",
            name:"应用名称",
        },
    },
    country: {
        name: {
            title:"国家"
        },
        table: {
            name:"名称",
            code:"代码",
            status:"状态",
            create_at:"创建于",
        },
        field: {
            
        },
    },
    meal: {
        name: {
            title:"计划套餐"
        },
        table: {
            name:"名称",
            amountPerMonth:"月费",
            textMessageNumber:"文本消息数量",
            mediaMessageNumber:"媒体消息数量",
            queuedMessageNumber:"队列消息数量",
            sort:"排序",
            status:"状态",
            createAt:"创建于",
            description:"描述",
            whatsappNumber:"设备数量",
            limited:"有限",
            unlimited:"无限",
            messagePerMinute:"每分钟消息数量",
            maxMediaSize:"最大媒体大小",
            apiAccess:"API 接入",
            webhook:"网络回调",
        },
        field: {
            
        },
    },
    userAccount: {
        name: {
            title:"代理账号",
            log:"日志",
        },
        table: {
            id:"ID",
            agentName:"代理名字",
            agentEmail:"代理邮箱",
            balance:"账户余额",
            commissionRate:"分成比例",
            createAt:"创建于",

        },
        field: {
            
        },
    },
    accountList: {
        name: {
            title:"收款日志",
        },
        table: {
            referenceNumber:"流水号#",
            account:"交易用户",
            amount:"入账金额",
            preAmount:"入账前金额",
            newAmount:"入账后金额",
            remark:"备注",
            createAt:"创建于",
        },
        field: {
            
        },
    },
    apiKey: {
        name: {
            title:"应用管理",
        },
        table: {


        },
        field: {
            
        },
    },
    fastAnswer: {
        name: {
            title:"直答",
        },
        table: {
            response:"回答"

        },
        field: {
            
        },
    },
    chatBox:{
        name: {
            chatList:"聊天列表",
            clientInfo:"客户信息",
            userInfo:"用户信息",
            addRemark:"添加备注",
            send:"发送",
            placeholder:"搜索内容",
            online:"在线",
            offline:"离线",
            userName:"用户名",
            mobile:"移动电话",
            address:"地址",
            companyName:"公司名字",
            remark:"备注",
            title:"标题",
        },
        table: {
            response:"Response",

        },
        field: {
            
        },
    },
    fakeNlp:{
      name: {
          title: "NLP 列表",

      },
      table: {
          id: "ID",
          code: "问题编号",
          question: "问题",
          answer: "答案",
          data_type: "数据类型",
      },
      field: {
        id: "ID",
        code: "问题编号",
        question: "问题",
        answer: "答案",
        data_type: "数据类型",
      },
      validate: {
          required: {
          }
      },
  },
  fakeNlpSession:{
    name: {
        title: "NLP会话列表",

    },
    table: {
        id: "ID",
        name: "姓名",
        phone: "电话",
    },
    field: {
      id: "ID",
      name: "姓名",
      phone: "电话",
    },
    validate: {
        required: {
        }
    },
},
fakeNlpMessage:{
  name: {
      title: "NLP消息列表",

  },
  table: {
      id: "ID",
      content: "内容",
      create_at: "创建于",
      is_from_user: "是否用户发送",
      session_id: "会话ID",
      name: "姓名",
      phone: "电话",
  },
  field: {
    id: "ID",
    content: "内容",
    create_at: "创建于",
    is_from_user: "是否用户发送",
    session_id: "会话ID",
    name: "姓名",
    phone: "电话",
  },
  validate: {
      required: {
      }
  },
},
}

export default zhTW
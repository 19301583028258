
import { Options, Vue } from "vue-class-component";
import { ConfigProvider} from "ant-design-vue";
import zhTW from "ant-design-vue/es/locale/zh_TW";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
@Options({
    name: "App",
    components: {
        ConfigProvider,
        
    },
    data() {
        return {
            locale: enUS,
            sockets:null
        };
    },
    watch: {
        // 监听 store里面的数据
        "$store.state.message": {
            deep: true,
            handler: function (newValue, oldValue) {
                let e = newValue;

                if (e.event == 'logout') { // 监听登出事件
                    this.$store.dispatch("logout");
                    this.$router.go({ path: "login" });
                }
            }
        }
    },
    mounted() {
        this.$store.commit("reset");
        // this.$store.commit("connectSocket");
        // this.$store.dispatch("socket");
        switch (this.$store.getters.getLanguage) {
            case "zhTW":
                this.locale = zhTW;
                this.$i18n.locale = "zhTW";
                break;
            case "enUS":
                this.locale = enUS;
                this.$i18n.locale = "enUS";
                break;
            case "zhCN":
                this.locale = zhCN;
                this.$i18n.locale = "zhCN";
                break;
        }
    },

})
export default class App extends Vue {}

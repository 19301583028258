import Vue, { createApp } from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import "@/assets/less/public.less"
import 'font-awesome/css/font-awesome.min.css';
// let socket = new VueSocketIO({
//     debug: true,
//     // 服务器端地址
//     connection: SocketIO('http://127.0.0.1:8360'),
//     vuex: {
//         store,
//         actionPrefix: 'SOCKET_',
//         mutationPrefix: 'SOCKET_'
//     }
// })
createApp(App).use(store).use(i18n).use(router).mount('#app')

let heart = {
    timeout:5000,
    timeoutObj:0,
    serverTimeoutObj:0,
    reset:function(){
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        return this;
    },
    start:function(ws:any){
        var self = this;
        this.timeoutObj && clearTimeout(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.timeoutObj = window.setTimeout(function(){
            ws.send(
                JSON.stringify({
                    event: 'ping',
                    data: new Date()
                })
            );
            self.serverTimeoutObj = window.setTimeout(function(){
                ws.close();
            },self.timeout)
        },this.timeout)
    }
}
export default heart
import { Options, Vue } from "vue-class-component";
import { Row, Col, Form, Input, Image, Radio, message ,Button,Alert} from "ant-design-vue";
import { LoginOutlined, UserOutlined } from "@ant-design/icons-vue";
import { Captcha } from "@/api/Captcha";
import { Login as apiLogin } from "@/api/Login";
import { LoginByToken as apiLoginByToken } from "@/api/LoginByToken";
import zhTW from "ant-design-vue/es/locale/zh_TW";
import enUS from "ant-design-vue/es/locale/en_US";
@Options({
  name: "Login",
  components: {
    Row,
    Col,
    LoginOutlined,
    UserOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Button,
    Alert
  },
  data() {
    return {
      login: {
        account: "",
        password: "",
      },
      error:null,
      isLoad:false,
      captchaImg: "",
      captchaExpire: 0,
      language: "zhTW",
      validateRules: {
        account: [
          {
            required: true,
            message: this.$t("login.validate.required.username"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("login.validate.required.password"),
            trigger: "blur",
          },
        ]
      },
    };
  },
  methods: {
    // get captcha
    getCaptcha() {
      const api = new Captcha();
      api.get({ type: 1 }).then((response: any) => {
        if (1 == parseInt(response.data.code)) {
          //this.captchaImg = response.data.data.image;
          this.login.captcha_key = response.data.data.key;
          this.captchaExpire = parseInt(response.data.data.expire_time);
        } else {
          this.captchaImg = "";
          this.login.captcha_key = "";
          this.captchaExpire = 0;
        }
      });
    },
    // change language
    changeLanguage() {
      switch (this.language) {
        case "zhTW":
          this.locale = zhTW;
          this.$i18n.locale = "zhTW";
          this.$store.commit("setLanguage", "zhTW");
          break;
        case "enUS":
          this.locale = enUS;
          this.$i18n.locale = "enUS";
          this.$store.commit("setLanguage", "enUS");
          break;
      }
    },
    // send login message
    sendLogin() {
      
      this.$refs["refLogin"]
        .validate()
        .then(() => {
          this.isLoad  =true;
          this.error = null;
          const api = new apiLogin();
          api.post(this.login).then((response: any) => {
            this.isLoad = false;
            if (0 == parseInt(response.data.errno)) {
              this.$store.commit("setToken", response.data.data.corsToken);
              this.$store.commit("setUser", response.data.data);
              message.success(response.data.errmsg);
              if(response.data.data.login_count <= 0){
                this.$router.push({ name: "device/index" });
              }else{
                this.$router.push({ path: "/" });
              }
              
              //改为在路由切换时触发
              // this.$store.dispatch("socket");
            } else {
              this.error = response.data.errmsg;
              //message.error(response.data.errmsg);
              // this.getCaptcha();
            }
          });
        })
        .catch();
    },
    // check login statuc
    checkLogin(authToken: string) {
      const api = new apiLoginByToken();
          api.post({
            authToken: authToken,
          }).then((response: any) => {
            if (0 == parseInt(response.data.errno)) {
              this.$store.commit("setToken", response.data.data.corsToken);
              this.$store.commit("setUser", response.data.data);
              message.success(response.data.errmsg);
              if(response.data.data.login_count <= 0){
                this.$router.push({ name: "device/index" });
              }else{
                this.$router.push({ path: "/" });
              }
              
              //改为在路由切换时触发
              // this.$store.dispatch("socket");
            } else {
              this.error = response.data.errmsg;
              //message.error(response.data.errmsg);
              // this.getCaptcha();
            }
          });
    },
  },
  mounted() {
    let authToken = this.$route.params.authToken[0]
    console.log(authToken);
    if(authToken){
      this.checkLogin(authToken);
    }
    this.language = this.$store.getters.getLanguage;
  },
})
export default class Login extends Vue { }